/* AdvancedPreloader.css */

.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 15px solid rgba(255, 255, 255, 0.3);
  border-top: 15px solid #3498db; /* You can customize the color */
  border-radius: 50%;
  width: 120px; /* Adjust the width to make it wider */
  height: 120px; /* Keep the height the same or adjust it accordingly */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
